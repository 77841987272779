.login-modal {
    .ant-modal-content {
        opacity: 1 !important;
        // padding:0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 10px !important;
        border-radius: 0px !important;

        .ant-modal-close {
            top: 15.84px !important;
            left: 10px !important;
            width: 18px !important;
            height: 18px !important;
        }

        .ant-modal-header {

            .ant-modal-title {
                text-align: center;
                font-size: 20px !important;
                line-height: 30px !important;
            }
        }
    }

    // .modalfooter{
    //     padding:0px 55px !important
    // }
    // .ant-modal-footer{
    //     display: none !important;
    // }
}

.signup-modal {
    .ant-modal-content {
        // padding:0px !important;
        opacity: 1 !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 10px !important;
        border-radius: 0px !important;

        .ant-modal-close {
            top: 15.84px !important;
            left: 10px !important;
            width: 18px !important;
            height: 18px !important;
        }

        .ant-modal-header {

            .ant-modal-title {
                text-align: center;
                font-size: 20px !important;
                line-height: 30px !important;
            }
        }
    }

    .ant-modal-footer {
        display: none !important;
    }
}

.checkout-login-with-alfa {
    width: 200px;
    display: flex;
    color: #fff !important;
    font-family: "ZemestroStd-Regular";
    background: linear-gradient(to right, #e52817, #a01d18);
    color: #fff;
    font-size: 12px;
    padding: 8px 25px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}